body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none !important; /* Safari */
  -ms-user-select: none !important; /* IE 10 and IE 11 */
  user-select: none !important; /* Standard syntax */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

 .centerZoom .leaflet-bottom {
  bottom: 12px !important
}

@font-face {
  font-family: "Catamaran-Black";
  src: local("Catamaran-Black"),
    url("./fonts/Catamaran-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Catamaran-Bold";
  src: local("Catamaran-Bold"),
    url("./fonts/Catamaran-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Catamaran-ExtraBold";
  src: local("Catamaran-ExtraBold"),
    url("./fonts/Catamaran-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Catamaran-ExtraLight";
  src: local("Catamaran-ExtraLight"),
    url("./fonts/Catamaran-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Catamaran-Light";
  src: local("Catamaran-Light"),
    url("./fonts/Catamaran-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Catamaran-Medium";
  src: local("Catamaran-Medium"),
    url("./fonts/Catamaran-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Catamaran-Regular";
  src: local("Catamaran-Regular"),
    url("./fonts/Catamaran-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Catamaran-SemiBold";
  src: local("Catamaran-SemiBold"),
    url("./fonts/Catamaran-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Catamaran-Thin";
  src: local("Catamaran-Thin"),
    url("./fonts/Catamaran-Thin.ttf") format("truetype");
}
